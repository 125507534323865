import { lazy } from "common/load/componentUtils";

import { useDrawer } from "client/common/hooks/stackables";

const FulfillerInfoDrawer = lazy(() => import("./FulfillerInfoDrawer"));
const ShippingInfoDrawer = lazy(() => import("./ShippingInfoDrawer"));

export const useFulfillerInfoDrawer = () => {
  const { open } = useDrawer();
  return {
    open: () => open(FulfillerInfoDrawer),
  };
};

export const useShippingInfoDrawer = () => {
  const { open } = useDrawer();
  return {
    open: () => open(ShippingInfoDrawer),
  };
};
